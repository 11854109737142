/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.cb-home {
  width: 310px;
  margin-left: 2.88461538%;
  margin-top: 26px;
}
.cb-page-title {
  width: 94.23076923%;
  margin-left: 2.88461538%;
  margin-right: 2.88461538%;
}
.moon {
  right: 0;
  top: -72px;
  width: auto;
  max-width: 100%;
}
.sidebg {
  float: right;
  padding-top: 29.296875%;
  padding-bottom: 80px;
  width: 27.5%;
  margin-right: 2.88461538%;
  margin-top: 0;
}
.cb-page-layout3 .maincontent,
.cb-page-layout4 .maincontent {
  margin-top: 0;
  width: 66.63461538%;
  padding-top: 28.64583333%;
  padding-bottom: 4.08496732%;
}
.authenticated .cb-page-layout3 .maincontent,
.authenticated .cb-page-layout4 .maincontent {
  padding-bottom: 4.08496732%;
}
#wrapper {
  overflow: visible;
}
.vcard {
  margin-left: 30px;
  width: auto;
  margin-right: 0;
}
.vcard.right {
  margin-left: 60px;
}
.vcard .vcardtitle {
  display: block;
  margin-right: 0;
}
#mobile-navi {
  -o-transition: all 0.418s ease-out;
  -moz-transition: all 0.418s ease-out;
  -webkit-transition: all 0.418s ease-out;
  transition: all 0.418s ease-out;
}
body.cb-toggle-target-active #mobile-navi {
  max-height: 300px;
  -o-transition: all 0.418s ease-out;
  -moz-transition: all 0.418s ease-out;
  -webkit-transition: all 0.418s ease-out;
  transition: all 0.418s ease-out;
}
.logoutlink {
  right: 2.88461538%;
  top: 14px;
}
div.grid .horz .trim td,
div.grid .horz .trim th {
  border-bottom: 1px dotted #8b7e7a;
  border-top: 1px dotted #8b7e7a;
}
.cb-page-layout1 div.main div.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-page-layout1 div.main div.grid div.body,
.cb-page-layout1 div.main div.grid table.horz {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-page-layout1 div.main div.grid div.body td,
.cb-page-layout1 div.main div.grid table.horz td {
  border-top: none;
}
#services {
  float: right;
  margin-right: 30px;
  font-size: 11px;
  color: #dba091;
  margin-top: 75px;
  margin-bottom: 31px;
}
#services .meta {
  color: #dba091;
  margin: 0 5px;
}
#services .meta:hover,
#services .meta:focus {
  color: #fff;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
}
.area .part,
.area > .grid table {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
  width: 94.23076923%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 94.23076923%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main h2,
.cb-page-layout2 .main .foot {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
}
.cb-page-layout2 .main .part,
.cb-page-layout2 .main > .grid table {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
  width: 94.23076923%;
}
.cb-page-layout2 .main .tiny {
  width: 44.23076923%;
}
.cb-page-layout2 .main > .slim {
  width: 25%;
}
.cb-page-layout2 .main > .slim h2,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  margin-right: 11.53846154%;
  margin-left: 11.53846154%;
}
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  width: 76.92307692%;
}
.cb-page-layout2 .main > .slim .tiny {
  width: 76.92307692%;
}
.cb-page-layout3 .main {
  width: 100%;
}
.cb-page-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout3 .main h2,
.cb-page-layout3 .main .foot {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
}
.cb-page-layout3 .main .part,
.cb-page-layout3 .main > .grid table {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
  width: 91.34199134%;
}
.cb-page-layout3 .main .tiny {
  width: 41.34199134%;
}
.cb-page-layout3 .main > .slim {
  width: 50%;
}
.cb-page-layout3 .main > .slim h2,
.cb-page-layout3 .main > .slim .foot,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  margin-right: 8.65800866%;
  margin-left: 8.65800866%;
}
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  width: 82.68398268%;
}
.cb-page-layout3 .main > .slim .tiny {
  width: 82.68398268%;
}
.cb-page-layout4 .main,
.north {
  width: 100%;
}
.cb-page-layout4 .main > .unit,
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout4 .main h2,
.north h2,
.cb-page-layout4 .main .foot,
.north .foot {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
}
.cb-page-layout4 .main .part,
.north .part,
.cb-page-layout4 .main > .grid table,
.north > .grid table {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
  width: 91.34199134%;
}
.cb-page-layout4 .main .tiny,
.north .tiny {
  width: 41.34199134%;
}
.cb-page-layout4 .main > .slim,
.north > .slim {
  width: 50%;
}
.cb-page-layout4 .main > .slim h2,
.north > .slim h2,
.cb-page-layout4 .main > .slim .foot,
.north > .slim .foot,
.cb-page-layout4 .main > .slim .part,
.north > .slim .part,
.cb-page-layout4 .main > .slim.grid table,
.north > .slim.grid table {
  margin-right: 8.65800866%;
  margin-left: 8.65800866%;
}
.cb-page-layout4 .main > .slim .part,
.north > .slim .part,
.cb-page-layout4 .main > .slim.grid table,
.north > .slim.grid table {
  width: 82.68398268%;
}
.cb-page-layout4 .main > .slim .tiny,
.north > .slim .tiny {
  width: 82.68398268%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 10.48951049%;
  margin-left: 10.48951049%;
}
.side .part,
.side > .grid table {
  margin-right: 10.48951049%;
  margin-left: 10.48951049%;
  width: 79.02097902%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 79.02097902%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  margin-top: 40px;
}
.cb-page-layout3 .main,
.cb-page-layout4 .main {
  margin-top: 0;
}
.cb-page-layout1 .main {
  float: none;
  margin: 0 auto;
  max-width: 500px;
  *zoom: 1;
  margin-top: 40px;
}
.cb-page-layout1 .main:before,
.cb-page-layout1 .main:after {
  display: table;
  content: '';
}
.cb-page-layout1 .main:after {
  clear: both;
}
.cb-multimood + .main {
  margin-top: 0;
}
.cb-page-layout1 div.unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
h3 {
  font-size: 21px;
}
.cb-page-layout2 h3 {
  font-size: 27px;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */